import { SignJWT } from 'jose';

export async function createJWT(payload) {
  const encoder = new TextEncoder();
  const encodedKey = encoder.encode('key');

  const jwt = await new SignJWT({ ...payload })
    .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
    .setIssuedAt()
    .setExpirationTime('30h') 
    .sign(encodedKey);

  return jwt;
}

export async function getTelegramJWT() {
  console.log("Address:", window.location.href);

  const hashParams = window.location.hash.substr(1);
  const hashParamsObj = new URLSearchParams(hashParams);
  const tgWebAppDataEncoded = hashParamsObj.get('tgWebAppData');

  // console.log("tgWebAppDataEncoded:", tgWebAppDataEncoded);

  if (tgWebAppDataEncoded) {
    const tgWebAppDataDecodedOnce = decodeURIComponent(tgWebAppDataEncoded);

    // console.log("tgWebAppDataDecodedOnce:", tgWebAppDataDecodedOnce);

    const tgWebAppDataDecodedTwice = decodeURIComponent(tgWebAppDataDecodedOnce);
    // console.log("tgWebAppDataDecodedTwice:", tgWebAppDataDecodedTwice);

    try {

      const dataCheckString = tgWebAppDataDecodedTwice
        .split('&')
        .filter(param => !param.startsWith('hash='))  
        .map(param => param.split('='))
        .sort((a, b) => a[0].localeCompare(b[0])) 
        .map(param => `${param[0]}=${param[1]}`) 
        .join('\n');

      console.log(dataCheckString);

      const hash = new URLSearchParams(tgWebAppDataDecodedTwice).get('hash');
      // console.log("Hash:", hash);

      const dataForCheck = {
        dataCheckString: dataCheckString,
        hash
      };

      return await createJWT(dataForCheck);


    } catch (error) {
      console.error("Error parsing tgWebAppData: ", error);
    }
  }
}
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { io, Socket } from "socket.io-client";

declare global {
    interface Window {
        unityInstance: any;
    }
}

class SocketController {
    private socket: Socket | null = null;
    private serverUrl: string = "";
    private currentRoomId: string | null = null;
    private sendMessage: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void;

    constructor(sendMessage: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void) {
        this.sendMessage = sendMessage;
    }

    public joinRoom(serverUrl: string, roomId: string) {
        //serverUrl = "http://49.13.63.160:4000";// TODO: remove after test
        // serverUrl = "http://localhost:4000";// TODO: remove after test
        if (this.serverUrl === serverUrl && this.currentRoomId === roomId) {
            console.log(`Already connected to ${serverUrl} and joined room ${roomId}`);
            return;
        }

        if (this.socket !== null && (this.serverUrl !== serverUrl || this.currentRoomId !== roomId)) {
            this.socket.disconnect();
            this.socket = null;
        }

        if (this.socket === null) {
            this.socket = io(serverUrl);
            this.serverUrl = serverUrl;
            this.setupBaseListeners();
        }

        if (this.currentRoomId !== roomId) {
            if (this.currentRoomId !== null) {

                this.socket.off(`multiplierUpdate${this.currentRoomId}`);
            }

            this.socket.on(`multiplierUpdate${roomId}`, (data: { id: string; multiplier: number }) => {
                console.log(`Multiplier update for round ${data.id}: ${data.multiplier}`);

                this.sendToUnity(data);
            });

            this.currentRoomId = roomId;
            console.log(`Joined new room ${roomId}`);
        }
    }

    private setupBaseListeners() {
        this.socket?.on("connect", () => {
            console.log("Connected to the server");
        });

        this.socket?.on("disconnect", () => {
            console.log("Disconnected from the server");
        });
    }

    private sendToUnity(data: any) {
        if (this.sendMessage) {
            this.sendMessage("ReactWrapper", "OnMultiplierReceived", JSON.stringify(data));
            console.log("Sending data to Unity:", data);
        } else {
            console.error("Unity instance is not defined. Unable to send data to Unity.");
        }
    }
}

export default SocketController;
import React, { useCallback, useEffect, useState } from "react";
import SocketController from '../SocketController';
import { getTelegramJWT } from './../utils/jwtUtils';
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";

type UnityMsgHandlerProps = {
  addEventListener: (event: string, handler: (...args: any[]) => void) => void;
  removeEventListener: (
    event: string,
    handler: (...args: any[]) => void
  ) => void;
  socketController: SocketController;
  sendMessage: (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void;
};

const UnityMsgHandler: React.FC<UnityMsgHandlerProps> = ({
  addEventListener,
  removeEventListener,
  socketController,
  sendMessage
}) => {

  const [getJWT, setGetJWT] = useState(false);

  const handleJoinRoom = useCallback((serverUrl: string, roomId: string) => {
    console.log(`Joining room: ${roomId} on server: ${serverUrl}`);
    socketController.joinRoom(serverUrl, roomId);
  }, [socketController]);

  const handleGetTelegramJWT = useCallback(() => {
    console.log(`GetTelegramJWT`);
    setGetJWT(true);
  }, []);

  const handleCopyInClipboard = useCallback((value: string) => {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'absolute'; 
    textarea.style.left = '-9999px';
    textarea.style.fontSize = '1px';
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }, []);

  useEffect(() => {
    if (getJWT) {
      setGetJWT(false);
      getTelegramJWT().then(jwt => {
        console.log("Send to Unity JWT: " + jwt);
        if (sendMessage) {
          sendMessage("ReactWrapper", "OnTelegramJWTReceived", JSON.stringify(jwt));
          console.log("Sending JWT to Unity:", jwt);
        } else {
          console.error("Unity instance is not defined. Unable to send data to Unity.");
        }
      }).catch(error => {
        console.error("Error get JWT:", error);
      });
    }
  }, [getJWT, sendMessage]);

  useEffect(() => {
    addEventListener("JoinRoom", handleJoinRoom);
    addEventListener("GetTelegramJWT", handleGetTelegramJWT);
    addEventListener("CopyInClipboard", handleCopyInClipboard);
    return () => {
      removeEventListener("JoinRoom", handleJoinRoom);
      removeEventListener("GetTelegramJWT", handleGetTelegramJWT);
      removeEventListener("CopyInClipboard", handleCopyInClipboard);
    };
  }, [addEventListener, removeEventListener, handleJoinRoom, handleGetTelegramJWT, handleCopyInClipboard]);




  return null;
};

export default UnityMsgHandler;
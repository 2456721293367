import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "src/app.module.css";
import SocketController from './SocketController';
import UnityMsgHandler from './UnityMsgHandler/UnityMsgHandler';
import { useEffect, useState } from "react";

const appName = "Game"; //enter your app file name from public/unitybuild folder

const App = () => {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    sendMessage,
  } = useUnityContext({
    loaderUrl: "/unitybuild/" + appName + ".loader.js",
    dataUrl: "/unitybuild/" + appName + ".data",
    frameworkUrl: "/unitybuild/" + appName + ".framework.js",
    codeUrl: "/unitybuild/" + appName + ".wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const [socketController, setSocketController] = useState<SocketController | null>(null);
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );


  useEffect(() => {
    if (isLoaded) {
      const controller = new SocketController(sendMessage);
      setSocketController(controller);
    }
  }, [isLoaded, sendMessage]);

  useEffect(
    function () {

      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };

      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );

      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );


  return (
    <div className={styles.container}>
      <div className={styles.unityWrapper}>
        {isLoaded === false && (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderWrapper}>
              <h1 className={styles.text}>
                Loading<span>.</span>
                <span>.</span>
                <span>.</span>
              </h1>
              <div className={styles.loadingBar}>
                <div className={styles.loadingBarFill}>
                  <div className={styles.loadingbarfillBg}>
                    <img
                      src="/images/loader-image.png"
                      className={styles.loadingbarimg}
                      style={{ width: loadingProgression * 300 }}
                      alt="loading..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{ display: isLoaded ? "block" : "none" }}
          devicePixelRatio={devicePixelRatio}
        />
      </div>
      {socketController && (
        <UnityMsgHandler
          addEventListener={addEventListener}
          removeEventListener={removeEventListener}
          socketController={socketController}
          sendMessage={sendMessage}
        />
      )}
    </div>
  );
};

export { App };
